.banner-pd-1 {
    padding: 30px 0px;
}

.banner-pd-2 {
    padding: 70px 0px;
}

.banner-pd-3 {
    padding: 50px 0px;
}


.p-6 {
    padding: 3.5rem;
}

.px-6 {
    padding-inline-start: 3.5rem;
    padding-inline-end: 3.5rem;
}

.py-6 {
    padding-block-start: 3.5rem;
    padding-block-end: 3.5rem;
}

@media (min-width: 576px) {
    .p-sm-6 {
        padding: 3.5rem;
    }
    
    .px-sm-6 {
        padding-inline-start: 3.5rem;
        padding-inline-end: 3.5rem;
    }
    
    .py-sm-6 {
        padding-block-start: 3.5rem;
        padding-block-end: 3.5rem;
    }
     
}

@media (min-width: 768px) {
    .p-md-6 {
        padding: 3.5rem;
    }

    .px-md-6 {
        padding-inline-start: 3.5rem;
        padding-inline-end: 3.5rem;
    }

    .py-md-6 {
        padding-block-start: 3.5rem;
        padding-block-end: 3.5rem;
    }
}

@media (min-width: 992px) {
    .p-lg-6 {
        padding: 3.5rem;
    }

    .px-lg-6 {
        padding-inline-start: 3.5rem;
        padding-inline-end: 3.5rem;
    }

    .py-lg-6 {
        padding-block-start: 3.5rem;
        padding-block-end: 3.5rem;
    }
}

@media (min-width: 1200px) {
    .p-xl-6 {
        padding: 3.5rem;
    }

    .px-xl-6 {
        padding-inline-start: 3.5rem;
        padding-inline-end: 3.5rem;
    }

    .py-xl-6 {
        padding-block-start: 3.5rem;
        padding-block-end: 3.5rem;
    }
}

@media (min-width: 1400px) {
    .p-xxl-6 {
        padding: 3.5rem;
    }

    .px-xxl-6 {
        padding-inline-start: 3.5rem;
        padding-inline-end: 3.5rem;
    }

    .py-xxl-6 {
        padding-block-start: 3.5rem;
        padding-block-end: 3.5rem;
    }
}
