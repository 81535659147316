[data-theme-mode="dark"] {
    @media (max-width: 991.98px) {
        &[toggled=open] .app-toggle-header .brand-main .desktop-logo.logo-dark {
            display: block;
        }

        &[toggled=open] .app-toggle-header .brand-main .desktop-logo.logo-color {
            display: none;
        }
    }

    .bg-pattern-1.bg-image1::before {
        opacity: 0.07;
    }

    .card .tool-img-white {
        display: block;
        opacity: 0.8;
    }

    .card .tool-img {
        display: none;
    }

    .bg-gray-custom {
        background-color: $gray-100;
    }

    .feature-card-13 {
        background: $custom-white url(../images/png/73.png) 95% 95%/auto no-repeat scroll;
        background-size: 20% 32%;
    }

    .pricing-icon {
        background-color: $primary-04;
        box-shadow: 0px 5px 10px $primary-02;
    }

    .choices[data-type*=select-one] .choices__button {
        filter: invert(1);
    }

    .auth-logo {
        &.logo-color {
            display: none;
        }

        &.logo-dark {
            display: block;
        }
    }

    .bg-light {
        background-color: $default-background !important;
    }

    .custom-bg::before {
        opacity: 0.05;
    }

    .modal-content {
        background-color: $custom-white;
    }

    .modal-header {
        border-bottom: 1px solid $border;
    }

    .btn-close {
        filter: invert(1);
    }

    .app-img-white {
        display: block;
        margin: 0 auto;
    }

    .app-img {
        display: none;
    }

    .dark-invert {
        filter: invert(1);
    }

    .dark-opacity1 {
        opacity: 0.1;
    }
}