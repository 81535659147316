.modal-header {
    padding: 1.25rem;
}

.modal-content {
    border: none;
}

.modal-body {
    padding: 1.25rem;
}

.offer-modal {
    .modal-content {
        background-color: $primary !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px -1px 20px 2px;

        &::before {
            content: "";
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            height: 100%;
            width: 100%;
            background-image: url(../images/backgrounds/15.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            opacity: 0.5;
            border-radius: 0.5rem;

        }
    }
}